import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f70975f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "publishing-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_publishing = _resolveComponent("publishing")!
  const _component_tir_tab = _resolveComponent("tir-tab")!
  const _component_publishing_history = _resolveComponent("publishing-history")!
  const _component_tir_tabs = _resolveComponent("tir-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tir_tabs, {
      shape: "line",
      "is-separator": false,
      activeId: _ctx.id,
      onSelect: _ctx.select
    }, {
      default: _withCtx((slotData) => [
        _createVNode(_component_tir_tab, {
          id: 0,
          title: 'Настройка публикации',
          "slot-data": slotData
        }, {
          default: _withCtx(() => [
            _createVNode(_component_publishing)
          ]),
          _: 2
        }, 1032, ["slot-data"]),
        _createVNode(_component_tir_tab, {
          id: 1,
          title: 'История публикаций',
          "slot-data": slotData
        }, {
          default: _withCtx(() => [
            _createVNode(_component_publishing_history)
          ]),
          _: 2
        }, 1032, ["slot-data"])
      ]),
      _: 1
    }, 8, ["activeId", "onSelect"])
  ]))
}