import Input from "vue3-tir-input"
import Control from "vue3-tir-control"
import Scroll from "vue3-tir-scroll"
import Error from "vue3-tir-error"
import Button from "vue3-tir-button"
import Modal from "vue3-tir-modal"
import Checkbox from "vue3-tir-checkbox"
import Dropdown from "vue3-tir-dropdown"
import List from "vue3-tir-list/tir-list.vue"
import Option from "vue3-tir-list/tir-option.vue"
import Popover from "vue3-tir-popover"
import Layout from 'progressme-vue3-layout-account'
import UiCusomTableComponent from 'progressme-vue3-ui-custom-table/ui-custom-table.vue'
import UiToggle from "vue3-tir-toggle"
import UiTag from "vue3-tir-tag"
import UiSearch from "progressme-vue3-ui-search"
import UiDebounce from "progressme-debounce"
import Tabs from "vue3-tir-tabs/tir-tabs.vue"
import Tab from "vue3-tir-tabs/tir-tab.vue"

let components = [
  ['tir-toggle', UiToggle],
  ['ui-input', Input],
  ['tir-control', Control],
  ['tir-scroll', Scroll],
  ['tir-error', Error],
  ['tir-button', Button],
  ['tir-modal', Modal],
  ['tir-checkbox', Checkbox],
  ['tir-dropdown', Dropdown],
  ['tir-list', List],
  ['tir-option', Option],
  ['tir-popover', Popover],
  ['layout-account', Layout],
  ['ui-custom-table', UiCusomTableComponent],
  ['tir-tag', UiTag],
  ['tir-search', UiSearch],
  ['tir-tabs', Tabs],
  ['tir-tab', Tab]
]

const registerNodeComponents = (app: any) => {
  components.forEach(el => app.component(el[0], el[1]));
}

export default registerNodeComponents;