import PublishingStage from "src/api/PublishingService/Response/PublishingStage";
import EnumTypeDeveloper from "src/publishing-admin-front/enum/EnumTypeDeveloper";

export default class PublishingOptions {
    stages: PublishingStage[];
    server: string;
    site: string;
    project: string;
    projectId: number;
    branch: string;
    isFullPublish: boolean;
    type: EnumTypeDeveloper;

    constructor(obj?: Partial<PublishingOptions>) {
        if (obj) Object.assign(this, obj);
    }
}