
import CustomTableCeilModel from "../../../components/table/models/CustomTableCeilModel";
import CustomTableModel from "../../../components/table/models/CustomTableModel";
import CustomTableRowModel from "../../../components/table/models/CustomTableRowModel";
import GetProjectPublicationHistoryItem from "src/api/PublishingService/Response/GetProjectPublicationHistoryItem";
import { Options, Vue, Prop, Watch } from "vue-property-decorator";

@Options({
  name: "publishing-history",
})
export default class PublishingHistory extends Vue {
  histories: CustomTableModel = null;

  visibleLog = false;
  log: string = null;
  errMsg: string = null;

  response: GetProjectPublicationHistoryItem[] = []; 

  created() {
    this.getHistory();
  }

  getHistory() {
    this.getTable([]);
    this.$api.PublishingApiService.getProjectsPublicationHistoryAsync().then(
      (response) => {
        if (response.IsSuccess) {
          this.response = response.Data;
          this.getTable(this.response);
        }
      }
    );
  }

  getTable(data: GetProjectPublicationHistoryItem[]) {
    var moment = require("moment");
    let histories = new CustomTableModel({
      Title: "",
      Header: {
        Ceils: [
          new CustomTableCeilModel({ Title: "Результат" }),
          new CustomTableCeilModel({ Title: "Сайт" }),
          new CustomTableCeilModel({ Title: "Проект" }),
          new CustomTableCeilModel({ Title: "Ветка" }),
          new CustomTableCeilModel({ Title: "Дата" }),
          new CustomTableCeilModel({ Title: "Автор" }),
        ],
        SubTable: null,
        VisibleSubTable: false,
      },
    });

    histories.Body = data.map(
      (item) =>
        new CustomTableRowModel({
          Class: item.IsPublished ? 'green' : 'red',
          Ceils: [
            new CustomTableCeilModel({ Title: item.IsPublished ? '+' : '-' }),
            new CustomTableCeilModel({ Title: item.SiteName }),
            new CustomTableCeilModel({ Title: item.ProjectName }),
            new CustomTableCeilModel({ Title: item.Branch }),
            new CustomTableCeilModel({
              Title: moment(item.Date).format("DD.MM.YYYY HH:mm"),
            }),
            new CustomTableCeilModel({ Title: item.Author }),
          ],
        })
    );

    this.histories = histories;
  }

  openLog(data: CustomTableCeilModel[], index: number) {
    this.visibleLog = true;
    this.$api.PublishingApiService.getProjectBuildLogAsync({
      LogId: this.response[index].LogId,
    }).then((response) => {
      if (response.IsSuccess) {
        this.log = response.Data;
      } else {
        this.errMsg = response.ErrorMessage;
      }
    });
  }
}
