
import SettingsPublishingHistory from "src/publishing-admin-front/models/modals/SettingsPublishingHistory";
import { Options, Vue } from "vue-property-decorator";
import { DropdownOptionModel } from "vue3-tir-dropdown/models";
import PublishingModal from "../../../components/modals/publishing-action-modal.vue";
import ViewBranch from "./view-branch.vue";
import BranchItem from "src/api/PublishingService/Response/BranchItem";
import CustomTableCeilModel from "src/publishing-admin-front/components/table/models/CustomTableCeilModel";
import PublishingStage from "src/api/PublishingService/Response/PublishingStage";
import PublishingOptions from "../../../models/modals/PublishingOptions";
import EnumTypeDeveloper from "../../../enum/EnumTypeDeveloper";
import ProjectItem from "src/api/PublishingService/Response/ProjectItem";
@Options({
  name: "publishing",
  components: {
    PublishingModal,
    ViewBranch,
  },
})
export default class Publishing extends Vue {
  get projectHistorySettings(): SettingsPublishingHistory {
    if (!this.activeProject || !this.activeSite || !this.activeServer)
      return null;

    return <SettingsPublishingHistory>{
      ProjectId: this.activeProject.Id,
      ProjectName: this.activeProject.Text,
      SiteName: this.activeSite.Text,
      ServerIp: this.activeServer.Text,
    };
  }

  get publishingOptions(): PublishingOptions {
    return {
      stages: this.stages,
      server: this.activeServer?.Text,
      site: this.activeSite?.Text,
      project: this.activeProject?.Text,
      projectId: this.activeProject?.Id,
      branch: this.activeBranch,
      isFullPublish: this.IsFullPublish,
      type: this.typeDeveloper,
    };
  }

  showPublishing = false;

  visibleHistoryModal = false;

  /**
   * Тип серверов live / тестовые
   */
  IsLive = false;

  /**
   * Тип front / back
   */
  typeDeveloper: EnumTypeDeveloper = EnumTypeDeveloper.Frontend;
  /**
   * Загрузка полная / частичная
   */
  IsFullPublish = false;

  /**
   * Список типов backend / frontend
   */

  types: Array<DropdownOptionModel> = [];

  /**
   * Список серверов
   */
  servers: Array<DropdownOptionModel> = [];

  /**
   * Список сайтов
   */
  sites: Array<DropdownOptionModel> = [];

  /**
   * Список проектов для публикации
   */
  projects: Array<ProjectItem> = [];

  projectsGroup: Array<DropdownOptionModel> = [];

  /**
   * Список веток для публикации
   */
  branchs: Array<BranchItem> = null;

  /**
   * Выбранный сервер
   */
  activeServer: DropdownOptionModel = null;
  /**
   * Выбранный сайт
   */
  activeSite: DropdownOptionModel = null;

  /**
   * Выбранный проект
   */
  activeProject: DropdownOptionModel = null;

  /**
   * Выбранная ветка
   */
  activeBranch: string = null;

  /**
   * Список этапов публикации
   */
  stages: PublishingStage[] = [];

  /**
   * Очистка временных файлов
   */
  isLoadingClearFiles = false;

  created() {
    document.title = "Автопубликация";

    this.getServers();
    this.getProjects();
  }

  /**
   * Выбор сервера
   */
  changeServer(server: DropdownOptionModel) {
    this.getSites(server.Text);
  }

  /**
   * Выбор проекта
   */
  changeProject(project: DropdownOptionModel) {
    this.branchs = null;

    this.getAllStages(project.Id);
    this.getBranchs(project.Id);
  }
  /**
   * Выбор типа frontend / backend
   */
  changeType(type: DropdownOptionModel) {
    this.activeProject = null;
    this.filterProject(type.Id);
  }

  changeBranch(branch: CustomTableCeilModel[]) {
    this.activeBranch = branch[0].Title;
  }

  getTypes(type: EnumTypeDeveloper) {
    this.types = [
      new DropdownOptionModel({
        Id: EnumTypeDeveloper.Frontend,
        Text: "Frontend",
        IsActive: type == EnumTypeDeveloper.Frontend,
      }),
      new DropdownOptionModel({
        Id: EnumTypeDeveloper.Backend,
        Text: "Backend",
        IsActive: type == EnumTypeDeveloper.Backend,
      }),
    ];
  }
  /**
   * Получение этапов публикации
   */
  getAllStages(projectId: number) {
    this.$api.PublishingApiService.getAllStagesAsync({
      ProjectId: projectId,
    }).then((response) => {
      if (response.IsSuccess) {
        this.stages = response.Data;
      }
    });
  }

  filterProject(type: EnumTypeDeveloper = null) {
    if (!type && type != 0) {
      this.projectsGroup = this.projects.map(
        (project) =>
          new DropdownOptionModel({
            Id: project.Id,
            Text: project.Name,
            IsActive: false,
          })
      );
    } else {
      this.projectsGroup = this.projects
        .filter((el) => el.Type.toString().includes(type.toString()))
        .map(
          (project) =>
            new DropdownOptionModel({
              Id: project.Id,
              Text: project.Name,
              IsActive: false,
            })
        );
    }
  }

  /**
   * Получение списка проектов для публикации
   */
  getProjects() {
    this.$api.PublishingApiService.getProjectsAsync().then((response) => {
      if (response.IsSuccess && response.Data) {
        this.getTypes(EnumTypeDeveloper.Frontend);
        this.projects = response.Data;
        this.filterProject();
      } else {
        if (response.ErrorMessage == "Необходима авторизация") {
          this.$router.push({ name: "login" });
        }
      }
    });
  }

  /**
   * Получение списка серверов для публикации
   */
  getServers() {
    this.activeServer = null;
    this.$api.PublishingApiService.getServersAsync({
      IsLive: this.IsLive,
    }).then((response) => {
      if (response.IsSuccess) {
        this.servers = response.Data.map(
          (server, index) =>
            new DropdownOptionModel({
              Id: index,
              Text: server,
              IsActive: false,
            })
        );
      }
    });
  }

  /**
   * Получение списка сайтов для публикации
   */
  getSites(server: string) {
    this.activeSite = null;
    this.$api.PublishingApiService.getSitesListAsync({
      ServerIp: server,
    }).then((response) => {
      if (response.IsSuccess) {
        this.sites = response.Data.map(
          (site, index) =>
            new DropdownOptionModel({
              Id: index,
              Text: site,
              IsActive: false,
            })
        );
      }
    });
  }

  /**
   * Получение списка веток для публикации
   */
  getBranchs(projectId: number) {
    this.activeBranch = null;
    this.$api.PublishingApiService.getProjectBranchesAsync({
      ProjectId: projectId,
    }).then((response) => {
      if (response.IsSuccess) {
        this.branchs = response.Data;
      }
    });
  }
  /**
   * Очистка временных файлов
   */
  clearTempFiles() {
    this.isLoadingClearFiles = true;
    this.$api.PublishingApiService.clearTempFilesAsync().then((response) => {
      this.isLoadingClearFiles = false;
    });
  }

  showConfirmPublishModal() {
    this.showPublishing = true;
  }
}
