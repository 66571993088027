export default class User {
    Id: number;
    Name: string;
    Login: string;
    IsAuthorized: boolean;

    constructor(obj?: Partial<User>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}