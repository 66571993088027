
import { Options, Vue, Prop, Watch } from "vue-property-decorator";

@Options({
  name: "publishing-container",
})
export default class PublishingContainer extends Vue {
  id: number = 0;

  select(id: number) {
    this.id = id;
  }
}
