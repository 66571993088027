
import { Options, Vue, Prop, Watch } from "vue-property-decorator";

@Options({
  name: "ui-preloader",
})
export default class UiPreloader extends Vue {
  @Prop({ default: false, type: Boolean})
  visible: boolean;
}
