import { createApp, } from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from './store'
import { createPinia } from 'pinia'
import registerNodeComponents from "./register-node-components"
import moment from "moment";

import "./assets/styles/hljs/style.css";
import "./assets/styles/main.less";
import "./assets/styles/field/main.less";
import "./assets/fonts/edvibe/style.css";

const pinia = createPinia()
const app = createApp(App as any);

registerNodeComponents(app);


import Api from './api';
let url = 'wss://publishing-admin-api.edvibe.com/websocket';
app.use(Api, { url: url });

app.use(pinia)

import registerComponents from "./register-components"
import "element-plus/theme-chalk/index.css"
registerComponents(app);
app.use(store)
app.use(router);
import authMiddleware from "./router/authMiddleware";
app.use(authMiddleware, { router })
app.mount("#app");