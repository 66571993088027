
import { Publishing, Configuration } from "../../../router/routerNames";
import { Vue, Options, Watch } from "vue-property-decorator";
@Options({
  name: "login",
})
export default class Login extends Vue {
  email: string = "";
  password: string = "";
  isError: boolean = false;

  errorPassword: boolean = false;
  errorEmail: boolean = false;

  created() {
    document.title = "Логин";
    this.email = "";
    this.password = "";
  }

  @Watch("email")
  @Watch("password")
  onUpdateLoginData() {
    this.isError = false;
    this.errorPassword = false;
    this.errorEmail = false;
  }

  login() {
    if (this.email.length && this.password.length) {
      this.$api.AuthService.loginAsync({
        Password: this.password,
        Login: this.email
      }).then((response) => {
        if (response.IsSuccess) {
          this.$store.state.IsAuthorized = true;
          this.$router.push({ name: Configuration });
        } else {
          this.isError = true;
        }
      })
    }
  }
}
