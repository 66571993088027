import User from "../api/AuthService/Response/User";
import { App } from "vue";
import { Router } from "vue-router";
import { Login, Configuration, Publishing } from "./routerNames";
import Api from "../api/apiDataSource";
import DataSource from "../dataSource/dataSource"

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $dataSource: DataSource;
  }
}


const install = (app: App, opts: { router: Router }) => {
  opts.router.beforeEach(async (to, from, next) => {
    let routes = [Login, Configuration, Publishing]

    const dataSubSource = new DataSource();  

    const api = app.config.globalProperties.$api as Api;
   
    if (!app.config.globalProperties.$dataSource)
      app.config.globalProperties.$dataSource = dataSubSource;
    const dataSource = app.config.globalProperties.$dataSource;
   
    let res
    let errorCode
    let isAuthorized = false;
    
    if (app.config.globalProperties.$dataSource.UserSource.User) {
      next()
    }
    
    try {
      res = await api.AuthService.getCurrrentUserAsync();
      dataSource.UserSource.User = new User(res.Data);
      isAuthorized = true;

      next({ name: Configuration })
    } catch (error) {
      errorCode = error.ErrorCode
    } 
    
    if (!isAuthorized && to.path != "/") {
      window.location.href = window.location.origin;
      return;
    }
    if (!isAuthorized) {
      next({ name: Login })
      return
    } 

    next()
  });
};
const dialog = {
  install,
};

export default dialog;

