import { App } from "vue";
import { Store } from "vuex";
import Vuex from 'vuex'

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $store: Store<StateModel>;
  }
}
export class StateModel {
  IsAuthorized: boolean = false;
}

const install = (app: App) => {
  const store = new Vuex.Store({
    state: new StateModel()
  })
  app.config.globalProperties.$store = store;
};

const store = {
  install,
};

export default store;