import AuthService from './AuthService/AuthService';
import { WebApiService } from './plugins/webApiService';
import PublishingApiService from './PublishingService/PublishingApiService';


export default class apiDataSource {
	webApiService: WebApiService;
	PublishingApiService: PublishingApiService;
	AuthService: AuthService;
	constructor(webApiUrl: string) {
		this.webApiService = new WebApiService(webApiUrl);
		this.PublishingApiService = new PublishingApiService(this.webApiService);
		this.AuthService = new AuthService(this.webApiService);
	}

}
