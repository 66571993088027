
import { Vue, Options, Prop, Watch } from "vue-property-decorator";
@Options({
  name: "login-input",
})
export default class LoginInput extends Vue {
  @Prop({ default: "", type: String }) modelValue: string;
  @Prop({ default: "text", type: String }) type: string;
  @Prop({ default: false, type: Boolean }) error: boolean;

  innerValue: string = "";

  get inputType(): string {
    if (this.type == "password") return "password";
    if (this.type == "phone") return "tel";
    if (this.type == "email") return "email";
    return "text";
  }

  get placeholder(): string {
    switch (this.type) {
      case "email":
        return "Эл. почта";
      case "password":
        return "Пароль";
      default:
        return "";
    }
  }

  get icon(): string {
    switch (this.type) {
      case "email":
        return require("../../../assets/icons/icon-email.svg");
      case "password":
        return require("../../../assets/icons/icon-password.svg");
      default:
        return "";
    }
  }

  @Watch("value") onUpdateValue() {
    this.innerValue = this.modelValue;
  }

  mounted() {
    this.onUpdateValue();
  }
}
