import { App } from 'vue'

// Регистрируем компоненты по уникальному названию файла


export default (app: App<Element>) => {
  const requireComponent = require.context(
    './publishing-admin-front',
    true,
    /[\w-]+\.vue$/
  )
  requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName)
    const componentName = fileName
      .replace(/^\.\//, '')
      .replace(/\.\w+$/, '')
      .split('/')
      .pop()
    app.component(
      componentName,
      componentConfig.default || componentConfig
    );
  })
}