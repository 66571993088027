import { WebApiService } from '../plugins/webApiService'
import { Events } from '../plugins/events'
import EventToAsyncDecorator from '../plugins/eventToAsyncDecorator'
import WsResponseModel from '../SchemaBuilderServer/Models/WsResponseModel';
import GetSitesListRequest from './Request/GetSitesListRequest';
import GetProjectBranchesRequest from './Request/GetProjectBranchesRequest';
import GetProjectPublicationHistoryRequest from './Request/GetProjectPublicationHistoryRequest';
import ProjectPublishRequest from './Request/ProjectPublishRequest';
import BaseResponse from '../Common/Models/BaseResponse';
import BaseResponseT from '../Common/Models/BaseResponseT';
import BranchItem from './Response/BranchItem';
import GetProjectPublicationHistoryItem from './Response/GetProjectPublicationHistoryItem';
import ProjectItem from './Response/ProjectItem';
import GetProjectBuildLogRequest from './Request/GetProjectBuildLogRequest';
import GetProjectBuildLogResponse from './Response/GetProjectBuildLogResponse';
import PublishingStage from './Response/PublishingStage';
import GetPublishingStageRequest from './Request/GetPublishingStageRequest';
import ProjectPublishResponse from './Response/ProjectPublishResponse';
import CurrentStep from './Response/CurrentStep';
import LastCommitInfo from './Response/LastCommitInfo';
import GetLastCommitRequest from './Request/GetLastCommitRequest';
import GetServersRequest from './Request/GetServersRequest';
import RepublishProjectResponse from './Response/RepublishProjectResponse';
import RepublishProjectRequest from './Request/RepublishProjectRequest';


export default class PublishingApiService {
	webApiService: WebApiService;
	GetSitesListEvent = new Events<WsResponseModel<BaseResponseT<string[]>>>();
	GetServersEvent = new Events<WsResponseModel<BaseResponseT<string[]>>>();
	GetProjectsEvent = new Events<WsResponseModel<BaseResponseT<ProjectItem[]>>>();
	GetProjectBranchesEvent = new Events<WsResponseModel<BaseResponseT<BranchItem[]>>>();
	GetProjectPublicationHistoryEvent = new Events<WsResponseModel<BaseResponseT<GetProjectPublicationHistoryItem[]>>>();
	ProjectPublishEvent = new Events<WsResponseModel<ProjectPublishResponse>>();
	GetProjectBuildLogEvent = new Events<WsResponseModel<BaseResponseT<string>>>();
	GetAllStagesEvent = new Events<WsResponseModel<BaseResponseT<PublishingStage[]>>>();
	GetCurrentStepEvent = new Events<WsResponseModel<BaseResponseT<CurrentStep>>>();
	GetTotalPublishTimeEvent = new Events<WsResponseModel<BaseResponseT<string>>>();
	GetLastCommitEvent = new Events<WsResponseModel<BaseResponseT<LastCommitInfo>>>();
	GetProjectsPublicationHistoryEvent = new Events<WsResponseModel<BaseResponseT<GetProjectPublicationHistoryItem[]>>>();
	RepublishProjectEvent = new Events<WsResponseModel<BaseResponseT<RepublishProjectResponse>>>();
	ClearTempFilesEvent = new Events<WsResponseModel<BaseResponse>>
	
	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('PublishingController', 'GetSites', (res: WsResponseModel<BaseResponseT<string[]>>) => {
			self.GetSitesListEvent.trigger(res);
		});
		webApiService.on('PublishingController', 'GetServers', (res: WsResponseModel<BaseResponseT<string[]>>) => {
			self.GetServersEvent.trigger(res);
		});
		webApiService.on('PublishingController', 'GetProjects', (res: WsResponseModel<BaseResponseT<ProjectItem[]>>) => {
			self.GetProjectsEvent.trigger(res);
		});
		webApiService.on('PublishingController', 'GetProjectBranches', (res: WsResponseModel<BaseResponseT<BranchItem[]>>) => {
			self.GetProjectBranchesEvent.trigger(res);
		});
		webApiService.on('PublishingController', 'GetProjectPublicationHistory', (res: WsResponseModel<BaseResponseT<GetProjectPublicationHistoryItem[]>>) => {
			self.GetProjectPublicationHistoryEvent.trigger(res);
		});
		webApiService.on('PublishingController', 'ProjectPublish', (res: WsResponseModel<ProjectPublishResponse>) => {
			self.ProjectPublishEvent.trigger(res);
		});
		webApiService.on('PublishingController', 'GetProjectBuildLog', (res: WsResponseModel<BaseResponseT<string>>) => {
			self.GetProjectBuildLogEvent.trigger(res);
		});
		webApiService.on('PublishingController', 'GetAllStages', (res: WsResponseModel<BaseResponseT<PublishingStage[]>>) => {
			self.GetAllStagesEvent.trigger(res);
		});
		webApiService.on('PublishingController', 'GetCurrentStep', (res: WsResponseModel<BaseResponseT<CurrentStep>>) => {
			self.GetCurrentStepEvent.trigger(res);
		});
		webApiService.on('PublishingController', 'GetTotalPublishTime', (res: WsResponseModel<BaseResponseT<string>>) => {
			self.GetTotalPublishTimeEvent.trigger(res);
		});
		webApiService.on('PublishingController', 'GetLastPublishedCommit', (res: WsResponseModel<BaseResponseT<LastCommitInfo>>) => {
			self.GetLastCommitEvent.trigger(res);
		});
		webApiService.on('PublishingController', 'GetAllPublicationHistory', (res: WsResponseModel<BaseResponseT<GetProjectPublicationHistoryItem[]>>) => {
			self.GetProjectsPublicationHistoryEvent.trigger(res);
		});
		webApiService.on('PublishingController', 'RepublishProject', (res: WsResponseModel<BaseResponseT<RepublishProjectResponse>>) => {
			self.RepublishProjectEvent.trigger(res);
		});
		webApiService.on('PublishingController', 'СlearTempFiles', (res: WsResponseModel<BaseResponse>) => {
			self.ClearTempFilesEvent.trigger(res);
		});
	}
	GetSitesList(req: GetSitesListRequest) {
		this.webApiService.send('PublishingController', 'GetSites', req);
	}
	GetServers(req: GetServersRequest) {
		this.webApiService.send('PublishingController', 'GetServers', req);
	}
	GetProjects() {
		this.webApiService.send('PublishingController', 'GetProjects');
	}
	GetProjectBranches(req: GetProjectBranchesRequest) {
		this.webApiService.send('PublishingController', 'GetProjectBranches', req);
	}
	GetProjectPublicationHistory(req: GetProjectPublicationHistoryRequest) {
		this.webApiService.send('PublishingController', 'GetProjectPublicationHistory', req);
	}
	ProjectPublish(req: ProjectPublishRequest) {
		this.webApiService.send('PublishingController', 'ProjectPublish', req);
	}
	GetProjectBuildLog(req: GetProjectBuildLogRequest) {
		this.webApiService.send('PublishingController', 'GetProjectBuildLog', req);
	}
	GetAllStages(req: GetPublishingStageRequest) {
		this.webApiService.send('PublishingController', 'GetAllStages', req);
	}
	GetCurrentStep() {
		this.webApiService.send('PublishingController', 'GetCurrentStep');
	}
	GetTotalPublishTime() {
		this.webApiService.send('PublishingController', 'GetTotalPublishTime');	
	}
	GetLastCommit(req: GetLastCommitRequest) {
		this.webApiService.send('PublishingController', 'GetLastPublishedCommit', req);	
	}
	GetProjectsPublicationHistory() {
		this.webApiService.send('PublishingController', 'GetAllPublicationHistory');
	}
	RepublishProject(req: RepublishProjectRequest) {
		this.webApiService.send('PublishingController', 'RepublishProject', req);
	}
	ClearTempFiles() {
		this.webApiService.send('PublishingController', 'ClearTempFiles');
	}
	// async methods
	async getSitesListAsync(req: GetSitesListRequest): Promise<BaseResponseT<string[]>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetSitesList.bind(this), this.GetSitesListEvent)(req);
	}
	async getServersAsync(req: GetServersRequest): Promise<BaseResponseT<string[]>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetServers.bind(this), this.GetServersEvent)(req);
	}
	async getProjectsAsync(): Promise<BaseResponseT<ProjectItem[]>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetProjects.bind(this), this.GetProjectsEvent)();
	}
	async getProjectBranchesAsync(req: GetProjectBranchesRequest): Promise<BaseResponseT<BranchItem[]>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetProjectBranches.bind(this), this.GetProjectBranchesEvent)(req);
	}
	async getProjectPublicationHistoryAsync(req: GetProjectPublicationHistoryRequest): Promise<BaseResponseT<GetProjectPublicationHistoryItem[]>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetProjectPublicationHistory.bind(this), this.GetProjectPublicationHistoryEvent)(req);
	}
	async projectPublishAsync(req: ProjectPublishRequest): Promise<ProjectPublishResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.ProjectPublish.bind(this), this.ProjectPublishEvent)(req);
	}
	async getProjectBuildLogAsync(req: GetProjectBuildLogRequest): Promise<BaseResponseT<string>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetProjectBuildLog.bind(this), this.GetProjectBuildLogEvent)(req);
	}
	async getAllStagesAsync(req: GetPublishingStageRequest): Promise<BaseResponseT<PublishingStage[]>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetAllStages.bind(this), this.GetAllStagesEvent)(req);
	}
	async getTotalPublishTimeAsync(): Promise<BaseResponseT<string>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetTotalPublishTime.bind(this), this.GetTotalPublishTimeEvent)();
	}
	async getLastCommitAsync(req: GetLastCommitRequest): Promise<BaseResponseT<LastCommitInfo>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetLastCommit.bind(this), this.GetLastCommitEvent)(req);
	}
	async getProjectsPublicationHistoryAsync(): Promise<BaseResponseT<GetProjectPublicationHistoryItem[]>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetProjectsPublicationHistory.bind(this), this.GetProjectsPublicationHistoryEvent)();
	}
	async republishProjectAsync(req: RepublishProjectRequest): Promise<BaseResponseT<RepublishProjectResponse>> {
		return EventToAsyncDecorator.getInstance().bind(this.RepublishProject.bind(this), this.RepublishProjectEvent)(req);
	}
	async clearTempFilesAsync(): Promise<BaseResponse> {
		return EventToAsyncDecorator.getInstance().bind(this.ClearTempFiles.bind(this), this.ClearTempFilesEvent)();
	}
}