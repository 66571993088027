
import moment = require("moment");
import CustomTableCeilModel from "progressme-vue3-ui-custom-table/models/CustomTableCeilModel";
import CustomTableModel from "progressme-vue3-ui-custom-table/models/CustomTableModel";
import CustomTableRowModel from "progressme-vue3-ui-custom-table/models/CustomTableRowModel";
import BranchItem from "src/api/PublishingService/Response/BranchItem";
import LastCommitInfo from "../../../../api/PublishingService/Response/LastCommitInfo";
import { Options, Vue, Prop, Watch } from "vue-property-decorator";
import { DropdownOptionModel } from "vue3-tir-dropdown/models";
import UiTable from "../../../components/table/ui-table.vue";
import PublishingOptions from "../../../models/modals/PublishingOptions";
@Options({
  name: "view-branch",
  components: {
    UiTable,
  },
})
export default class ViewBranch extends Vue {
  @Prop({ default: () => [] as DropdownOptionModel[] })
  options: BranchItem[];
  @Prop({ default: null })
  publishingOptions: PublishingOptions;

  branchs: CustomTableModel = null;
  lastCommit: LastCommitInfo = null;

  search: string = null;

  get projectId(): number {
    return this.publishingOptions.projectId;
  }

  get serverIp(): string {
    return this.publishingOptions.server;
  }

  onSearch(value: string) {
    this.search = value;

    this.getTable(this.options);
    this.branchs.Body = this.branchs.Body.filter((b) => {
      if (value == "") return true;
      if (b.Ceils[0].Title.toLowerCase().indexOf(value.toLowerCase()) != -1)
        return true;
      return false;
    });
  }

  @Watch("options", { deep: true })
  loadOptions() {
    this.getTable(this.options);
  }

  created() {
    this.getTable(this.options);
    this.getLastCommit();
  }

  /**
   * Получение последнего опубликованного коммита
   */
  getLastCommit() {
    this.$api.PublishingApiService.getLastCommitAsync({
      ProjectId: this.projectId,
      ServerIp: this.serverIp,
    }).then((response) => {
      if (response.IsSuccess) {
        response.Data.DateCreate = moment(response.Data.DateCreate).format(
          "DD.MM.YYYY hh:mm"
        );
        this.lastCommit = response.Data;
      }
    });
  }

  sort(idx: number) {
    this.branchs = null;

    this.getTable(this.options);

    if (idx == 0) {
      this.branchs.Body = this.branchs.Body.sort((a, b) => {
        var nameA = a.Ceils[0].Title.toLowerCase(),
          nameB = b.Ceils[0].Title.toLowerCase();
        if (nameA < nameB)
          //сортируем строки по возрастанию
          return 1;
        if (nameA > nameB) return -1;
        return 0; // Никакой сортировки
      });
    } else if (idx == 1) {
      this.branchs.Body = this.branchs.Body.sort(
        (a, b) =>
          moment(b.Ceils[1].Title).toDate().getTime() -
          moment(a.Ceils[1].Title).toDate().getTime()
      );
    }
  }

  getTable(data: BranchItem[]) {
    var moment = require("moment");
    let branchs = new CustomTableModel({
      Header: {
        Ceils: [
          new CustomTableCeilModel({ Title: "Ветка" }),
          new CustomTableCeilModel({ Title: "Дата" }),
          new CustomTableCeilModel({ Title: "Название комита" }),
          new CustomTableCeilModel({ Title: "Автор" }),
        ],
        SubTable: null,
        VisibleSubTable: false,
      },
    });

    branchs.Body = data.map(
      (item) =>
        new CustomTableRowModel({
          Ceils: [
            new CustomTableCeilModel({ Title: item.Name }),
            new CustomTableCeilModel({
              Title: moment(item.Date).format("DD.MM.YYYY HH:mm"),
            }),
            new CustomTableCeilModel({
              Title: item.CommitName,
            }),
            new CustomTableCeilModel({ Title: item.Author }),
          ],
        })
    );

    this.branchs = branchs;
  }
}
