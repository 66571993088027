import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Vue from 'vue'
import PublishingPage from "../publishing-admin-front/pages/page-publishing.vue";
import LoginPage from "../publishing-admin-front/pages/login/login.vue";
import PublishingConfiguration from "../publishing-admin-front/pages/publishing/publishing-container.vue";
import { Publishing, Login, Configuration } from './routerNames'
import User from 'src/api/AuthService/Response/User';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login',
    children: [
      {
        path: '/login',
        name: Login,
        component: LoginPage as any,
      },
      {
        path: '/publishing',
        name: Publishing,
        component: PublishingPage as any,
        children: [
          { 
            path: '/publishing/configuration', 
            component: PublishingConfiguration as any, 
            name: Configuration 
          }
        ],
        meta: {
          title: 'Автопубликация'
        }
      },
    ]
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})


export default router