import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1eb2b695"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login" }
const _hoisted_2 = { class: "inputs-wrapper" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_login_input = _resolveComponent("login-input")!
  const _component_login_sample = _resolveComponent("login-sample")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_login_sample, {
      headerText: "Вход в аккаунт",
      onLogin: _ctx.login
    }, {
      btn: _withCtx(() => [
        _createTextVNode(" Войти "),
        _createElementVNode("img", {
          src: require('../../../assets/icons/login-white.svg')
        }, null, 8, _hoisted_3)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("form", {
            class: _normalizeClass(["inputs-wrapper", { error: _ctx.isError }])
          }, [
            _createVNode(_component_login_input, {
              type: "email",
              modelValue: _ctx.email,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
              error: _ctx.isError || _ctx.errorEmail
            }, null, 8, ["modelValue", "error"]),
            _createVNode(_component_login_input, {
              type: "password",
              modelValue: _ctx.password,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
              error: _ctx.isError || _ctx.errorPassword
            }, null, 8, ["modelValue", "error"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["error-message", { show: _ctx.isError }])
          }, " Login or password are wrong. ", 2)
        ])
      ]),
      _: 1
    }, 8, ["onLogin"])
  ]))
}