
import PublishingOptions from "../../models/modals/PublishingOptions";
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import ProjectPublishRequest from "src/api/PublishingService/Request/ProjectPublishRequest";
import WsResponseModel from "src/api/SchemaBuilderServer/Models/WsResponseModel";
import CurrentStep from "src/api/PublishingService/Response/CurrentStep";
import PublishingStage from "src/api/PublishingService/Response/PublishingStage";

@Options({ name: "publishing-action-modal", emits: ["publishing"] })
export default class PublishingActionModal extends Vue {
  @Prop({ default: false, type: Boolean })
  visible: boolean;
  @Prop({ default: () => {}, type: Object })
  options: PublishingOptions;

  isLoading = false;

  password: string = null;
  err: string = null;

  totalTime: string = null;

  stages: { stage: PublishingStage; time: string; completed: boolean }[] = [];

  successShow = false;
  errorShow = false;

  errorProjectGuid: string = null;

  /** Получение списка всех шагов публикации */
  getStages(): { stage: PublishingStage; time: string; completed: boolean }[] {
    let steps = this.options.stages.map((option) => {
      return {
        stage: option,
        time: "",
        completed: false,
      };
    });

    return steps;
  }

  @Watch("options", { deep: true })
  onChangeOptions() {
    this.stages = this.getStages();
  }

  created() {
    this.$api.PublishingApiService.GetCurrentStepEvent.on(
      this.onChangeCurrentStep.bind(this)
    );

    this.stages = this.getStages();
  }
  /** Изменение статуса публикации */
  onChangeCurrentStep(response: WsResponseModel<CurrentStep>) {
    const publishingStep = document.getElementById("publishingStep");
    if (!!publishingStep)
      (publishingStep as HTMLAudioElement).play().then(
        () => {},
        () => {}
      );
    if (!response.IsSuccess) return;
    this.stages.forEach((stage, index) => {
      if (stage.stage.Number == response.Value.CurrentStage && index != 0) {
        this.stages[index - 1].time =
          response.Value.TimeExecutionPreviousStep.split(".")[0];
        this.stages[index - 1].completed = true;
      }
    });
  }
  /** Публикация проекта */
  publishing() {
    this.isLoading = true;

    this.$api.PublishingApiService.projectPublishAsync(
      this.getRequestPublish()
    ).then((response) => {
      if (response.IsSuccess) {
        this.totalTime = response.TotalPublishTime.split(".")[0];
        this.stages[this.stages.length - 1].completed = true;
        this.successShow = true;
      } else {
        if (response.ErrorMessage == "Неправильный пароль") {
          setTimeout(() => {
            this.err = null;
          }, 2000);
          this.isLoading = false;
        } else if (response.ErrorProjectGuid) {
          this.errorProjectGuid = response.ErrorProjectGuid;
          this.isLoading = false;
        } else {
          this.errorShow = true;
        }

        this.err = response.ErrorMessage;
      }

      const publishingFinish = document.getElementById("publishingFinish");
      if (!!publishingFinish)
        (publishingFinish as HTMLAudioElement).play().then(
          () => {},
          () => {}
        );
    });
  }

  /** Публикация проекта с момента на котором она прервалась */
  republishing() {
    this.isLoading = true;

    this.$api.PublishingApiService.republishProjectAsync(
      {
        ProjectGuid: this.errorProjectGuid,
        IsFullPublish: false
      }
    ).then((response) => {
      if (response.IsSuccess) {
        this.totalTime = response.Data.TotalPublishTime.split(".")[0];
        this.stages[this.stages.length - 1].completed = true;
        this.successShow = true;
      } else {
        this.errorShow = true;
        this.isLoading = false;
        this.err = response.ErrorMessage;
      }

      const publishingFinish = document.getElementById("publishingFinish");
      if (!!publishingFinish)
        (publishingFinish as HTMLAudioElement).play().then(
          () => {},
          () => {}
        );
    });
  }

  close() {
    this.errorShow = false;
    this.successShow = false;
    this.isLoading = false;
    this.$emit("close");
  }

  getRequestPublish(): ProjectPublishRequest {
    return <ProjectPublishRequest>{
      ServerIp: this.options.server,
      SiteName: this.options.site,
      ProjectId: this.options.projectId,
      BranchName: this.options.branch,
      IsFullPublish: this.options.isFullPublish,
      PublicationPassword: this.password,
    };
  }
}
