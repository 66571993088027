
import GetProjectPublicationHistoryItem from "src/api/PublishingService/Response/GetProjectPublicationHistoryItem";
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import SettingsPublishingHistory from "../../models/modals/SettingsPublishingHistory";
import CustomTableCeilModel from "../table/models/CustomTableCeilModel";
import CustomTableModel from "../table/models/CustomTableModel";
import CustomTableRowModel from "../table/models/CustomTableRowModel";
@Options({ name: "publishing-history-modal" })
export default class PublishingHistoryModal extends Vue {
  @Prop({ default: false, type: Boolean }) visible: boolean;
  @Prop({ default: null, type: Object }) project: SettingsPublishingHistory;

  response: GetProjectPublicationHistoryItem[] = [];
  histories: CustomTableModel = null;

  log: string = null;
  errMsg: string = null;

  visibleLog = false;

  @Watch("visible")
  onChangeVisible() {
    if (this.visible) {
      this.getHistory();
    } else {
      this.histories = null;
    }
  }

  getHistory() {
    this.$api.PublishingApiService.getProjectPublicationHistoryAsync({
      ProjectId: this.project.ProjectId,
      ServerIp: this.project.ServerIp,
      SiteName: this.project.SiteName,
    }).then((response) => {
      if (response.IsSuccess) {
        this.response = response.Data;
        this.getTable(response.Data);
      }
    });
  }

  getTable(data: GetProjectPublicationHistoryItem[]) {
    var moment = require("moment");
    let histories = new CustomTableModel({
      Title: this.project.ProjectName,
      Header: {
        Ceils: [
          new CustomTableCeilModel({ Title: "Результат публикации" }),
          new CustomTableCeilModel({ Title: "Ветка" }),
          new CustomTableCeilModel({ Title: "Дата" }),
          new CustomTableCeilModel({ Title: "Автор" }),
        ],
        SubTable: null,
        VisibleSubTable: false,
      },
    });

    histories.Body = data.map(
      (item) =>
        new CustomTableRowModel({
          Class: item.IsPublished ? 'green' : 'red',
          Ceils: [
            new CustomTableCeilModel({ Title: item.IsPublished ? 'Опубликовано' : 'Не опубликовано' }),
            new CustomTableCeilModel({ Title: item.Branch }),
            new CustomTableCeilModel({
              Title: moment(item.Date).format("DD.MM.YYYY HH:mm"),
            }),
            new CustomTableCeilModel({ Title: item.Author  }),
          ],
        })
    );

    this.histories = histories;
  }

  openLog(data: CustomTableCeilModel[], index: number) {
    this.visibleLog = true;
    this.$api.PublishingApiService.getProjectBuildLogAsync({
      LogId: this.response[index].LogId,
    }).then((response) => {
      if (response.IsSuccess) {
        this.log = response.Data;
      } else {
        this.errMsg = response.ErrorMessage;
      }
    });
  }
}
