import { WebApiService } from '../plugins/webApiService'
import { Events } from '../plugins/events'
import EventToAsyncDecorator from '../plugins/eventToAsyncDecorator'
import WsResponseModel from '../SchemaBuilderServer/Models/WsResponseModel';
import BaseResponse from '../Common/Models/BaseResponse';
import BaseResponseT from '../Common/Models/BaseResponseT';
import LoginRequest from './Request/LoginRequest';
import User from './Response/User';



export default class AuthService {
	webApiService: WebApiService;
	LoginEvent = new Events<WsResponseModel<BaseResponseT<BaseResponse>>>();
	LogoutEvent = new Events<WsResponseModel<BaseResponseT<BaseResponse>>>();
	GetCurrrentUserEvent = new Events<WsResponseModel<BaseResponseT<User>>>
	
	// async methods
	async logoutAsync(): Promise<BaseResponseT<BaseResponse>> {
		return EventToAsyncDecorator.getInstance().bind(this.Logout.bind(this), this.LogoutEvent)();
	}
	async loginAsync(req: LoginRequest): Promise<BaseResponseT<BaseResponse>> {
		return EventToAsyncDecorator.getInstance().bind(this.Login.bind(this), this.LoginEvent)(req);
	}
	async getCurrrentUserAsync(): Promise<BaseResponseT<User>> {
		return EventToAsyncDecorator.getInstance().bind(this.GetCurrrentUser.bind(this), this.GetCurrrentUserEvent)();
	}	

	constructor(webApiService: WebApiService) {
		const self = this;
		this.webApiService = webApiService;
		webApiService.on('AuthController', 'Logout', (res: WsResponseModel<BaseResponseT<BaseResponse>>) => {
			self.LogoutEvent.trigger(res);
		});
		webApiService.on('AuthController', 'Login', (res: WsResponseModel<BaseResponseT<BaseResponse>>) => {
			self.LoginEvent.trigger(res);
		});	
		webApiService.on('AuthController', 'GetCurrrentUser', (res: WsResponseModel<BaseResponseT<User>>) => {
			self.GetCurrrentUserEvent.trigger(res);
		});	
	}
	
	Logout() {
		this.webApiService.send('AuthController', 'Logout');
	}
	Login(req: LoginRequest) {
		this.webApiService.send('AuthController', 'Login', req);
	}
	GetCurrrentUser() {
		this.webApiService.send('AuthController', 'GetCurrrentUser');
	}

}