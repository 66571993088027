import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "login-block" }
const _hoisted_2 = { class: "login-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.headerText), 1),
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.$slots.btn)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "login-btn",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('login')))
        }, [
          _renderSlot(_ctx.$slots, "btn")
        ]))
      : _createCommentVNode("", true)
  ]))
}