import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78a849f9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login-input-wrapper" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["type", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.icon,
      class: _normalizeClass(["login-input-icon", [_ctx.type]])
    }, null, 10, _hoisted_2),
    _withDirectives(_createElementVNode("input", {
      class: _normalizeClass([{ error: _ctx.error }, "login-input"]),
      autocomplete: "",
      type: _ctx.inputType,
      placeholder: _ctx.placeholder,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
      onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('enter')), ["enter"])),
      onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:modelValue', _ctx.innerValue)))
    }, null, 42, _hoisted_3), [
      [_vModelDynamic, _ctx.innerValue]
    ])
  ]))
}