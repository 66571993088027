
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import CustomTableModel from "./models/CustomTableModel";

@Options({
  name: "ui-table",
})
export default class UiTableComponent extends Vue {
  @Prop({ default: () => null }) customTableModel: CustomTableModel;

  select(index: number) {
    this.customTableModel.Body.forEach((row, position) => {
      if (position == index) row.Selected = true
      else row.Selected = false
    })
  }
}
